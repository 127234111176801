<template>
  <vs-dialog v-model="openRegDialog" blur prevent-close not-close>
    <template #header>
      <h4 class="not-margin">Bienvenido a <b>TraumaReports</b></h4>
    </template>

    <div class="con-form">
      <vs-input
        v-model="name"
        state="primary"
        label-placeholder="Nombre"
        class="inputLogin"
      >
        <template #icon>
          <i class="fas fa-user"></i>
        </template>
        <template v-if="name === ''" #message-danger>
          Required
        </template>
      </vs-input>
      <vs-input
        v-model="lastname"
        state="primary"
        label-placeholder="Apellidos"
        class="inputLogin"
      >
        <template #icon>
          <i class="far fa-user"></i>
        </template>
        <template v-if="lastname === ''" #message-danger>
          Required
        </template>
      </vs-input>
      <vs-input
        v-model="email"
        state="primary"
        label-placeholder="Email"
        class="inputLogin"
      >
        <template #icon>
          @
        </template>
        <template v-if="email === ''" #message-danger>
          Required
        </template>
        <template v-if="!validEmail && email !== ''" #message-warn>
          {{ email !== "" ? "Email Invalid" : "Required" }}
        </template>
      </vs-input>
      <vs-input
        v-model="dni"
        state="primary"
        label-placeholder="Documento de identidad"
        class="inputLogin"
      >
        <template #icon>
          <i class="far fa-id-card"></i>
        </template>
        <template v-if="dni === ''" #message-danger>
          Required
        </template>
      </vs-input>
      <vs-input
        type="password"
        v-model="password"
        state="primary"
        label-placeholder="Password"
        class="inputLogin"
        :progress="getProgress"
      >
        <template #icon>
          <i class="fas fa-lock"></i>
        </template>
        <template v-if="getProgress >= 100" #message-success>
          Secure password
        </template>
        <template v-if="password === ''" #message-danger>
          Required
        </template>
      </vs-input>
      <vs-input
        type="password"
        v-model="password2"
        state="primary"
        label-placeholder="Repetir Password"
        class="inputLogin"
      >
        <template #icon>
          <i class="fas fa-lock"></i>
        </template>
        <template v-if="password2 === ''" #message-danger>
          Required
        </template>
        <template
          v-if="password2 !== password && password2 !== ''"
          #message-warn
        >
          La contraseña no coincide
        </template>
      </vs-input>
      <vs-switch v-model="isDoctor">
        <template #circle>
          <i v-if="!isDoctor" class="fas fa-user"></i>
          <i v-else class="fas fa-user-nurse"></i>
        </template>
        <template #on>
          Soy Doctor/a
        </template>
        <template #off>
          Soy Usuario
        </template>
      </vs-switch>
      <vs-checkbox v-model="remember">{{'Acepto las '}}&nbsp;<a style="margin: 0 10px;" href="/legal">Condiciones legales</a>&nbsp;{{' y '}}&nbsp;<a style="margin: 0 10px;" href="/politic">Política de uso</a>.</vs-checkbox>
      <!-- <div class="flex">
        
        <a href="#">Has olvidado la contraseña?</a>
      </div> -->
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block @click="onRegister" :disabled="!remember" :transparent="!remember">
          Registrar
        </vs-button>

        <div class="new">Ya tienes cuenta? <a href="/login">Entrar</a></div>
      </div>
      <!-- <small>
        Powered by
        <a
          href="http://www.clinikform.com"
          target="_blank"
          rel="noopener noreferrer"
          >ClinikForm</a
        >
      </small>
      <img width="30px" :src="require('@/assets/clinikform.png')" /> -->
    </template>
  </vs-dialog>
</template>

<script>
import { authMix } from "@/mixins/mixins.js";

export default {
  name: "RegisterPage",
  mixins: [authMix],
  data() {
    return {
      remember:false,
      openRegDialog: true,
      name: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      dni:"",
      isDoctor: false,
    };
  },
  methods: {
    async onRegister() {
      const { email, password, password2, name, lastname, isDoctor, dni } = this;
      if (
        email !== "" &&
        password2 === password &&
        password !== "" &&
        name !== "" &&
        lastname !== "" &&
        dni !== ""
      ) {
        const cred = await this.firebaseRegister(email, password);
        if (cred) {
          const data = {
            id: cred.user.uid,
            isDoctor,
            description: isDoctor
              ? `Apartado de información personal que será visible para sus pacientes. 
              Complételo con un breve resumen de su perfil profesional`
              : "Apartado de información personal que será visible para sus mèdicas",
            gender: false,
            birthDate: null,
            medicalHistory: [],
            enableMail: false,
            enablePush: false,
            dni
          };
          const displayName = name.concat(" " + lastname);
          this.firebaseCreateUser(cred.user.uid, data, displayName, email);
        }
        this.$router.push("/login");
      }
    },
  },
  mounted() {},
  computed: {
    validEmail() {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    getProgress() {
      let progress = 0;
      // Min un numero
      if (/\d/.test(this.password)) {
        progress += 20;
      }
      // Min una letra mayuscula
      if (/(.*[A-Z].*)/.test(this.password)) {
        progress += 20;
      }
      // Min una letra minuscula
      if (/(.*[a-z].*)/.test(this.password)) {
        progress += 20;
      }
      // Más de 5 caracteres
      if (this.password.length >= 6) {
        progress += 20;
      }
      // Min un caracter especial
      if (/[^A-Za-z0-9]/.test(this.password)) {
        progress += 20;
      }
      return progress;
    },
  },
};
</script>

<style>
.con-form > div {
  margin-bottom: 1.5rem;
}

.inputLogin > div > .vs-input {
  max-width: none;
  width: 100%;
}
</style>
